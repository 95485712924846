import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Page, { Section } from '../../shared/page-commons'
import SectionHeader from '../../shared/section-header'
import * as defaults from '../../shared/markdown-defaults'
import AriadnaBanner from './_ariadna-banner'
import { dateFormatter } from '../../shared/formatters'
import Link from '../../shared/link'

const DateField = styled.span`
  min-width: 130px;
  display: inline-block;
`

export default function AriadnaCallsPage(props) {
  return (
    <Page bgColor="surface">
      <AriadnaBanner />
      <Section textColumn>
        <SectionHeader>Ariadna Studies</SectionHeader>
        <defaults.h2>Ongoing calls</defaults.h2>
        {props.data.ongoing.nodes.length > 0 ? (
          <defaults.ul>
            {props.data.ongoing.nodes.map(({ frontmatter, fields }) => {
              return (
                <defaults.li key={frontmatter.title}>
                  <DateField>
                    {dateFormatter.format(new Date(frontmatter.date))}
                  </DateField>
                  <defaults.a
                    as={Link}
                    document={frontmatter.document_url}
                    href={frontmatter.external_url}
                    to={frontmatter.internal_url || fields.slug}
                  >
                    {frontmatter.title}
                  </defaults.a>
                </defaults.li>
              )
            })}
          </defaults.ul>
        ) : (
          <defaults.p>
            Sorry, no calls are opened right now! <br />
            Make sure to be inscribed to our{' '}
            <defaults.a href="https://public.govdelivery.com/accounts/EUESA/subscriber/new?topic_id%3DEUESA_44&sa=D&source=hangouts&ust=1528891356178000&usg=AFQjCNGqDPlQfMT-WGTJQEoZZF0zwHxWAQ">
              newsletter
            </defaults.a>{' '}
            not to lose future call openings.
          </defaults.p>
        )}
        <defaults.h2>Closed calls</defaults.h2>
        <defaults.ul>
          {props.data.closed.nodes.map(({ frontmatter, fields }) => {
            return (
              <defaults.li key={frontmatter.title}>
                <DateField>
                  {dateFormatter.format(new Date(frontmatter.date))}
                </DateField>
                <defaults.a
                  as={Link}
                  document={frontmatter.document_url}
                  href={frontmatter.external_url}
                  to={frontmatter.internal_url || fields.slug}
                >
                  {frontmatter.title}
                </defaults.a>
              </defaults.li>
            )
          })}
        </defaults.ul>
      </Section>
    </Page>
  )
}

export const query = graphql`
  query AriadnaCallsPage {
    ongoing: allMdx(
      filter: {
        fileAbsolutePath: { glob: "**/src/collections/ariadna/*" }
        frontmatter: { ongoing: { eq: true } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          date
          title
          document_url
          external_url
          internal_url
        }
        fields {
          slug
        }
      }
    }
    closed: allMdx(
      filter: {
        fileAbsolutePath: { glob: "**/src/collections/ariadna/*" }
        frontmatter: { ongoing: { eq: false } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          date
          title
          document_url
          external_url
          internal_url
        }
        fields {
          slug
        }
      }
    }
  }
`
